import React from 'react';
import Topbar from "../components/topbar";
import BottomBar from "../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/page.css';
import Smiles from "../img/about/smiles.webp";

import Blue from '../img/banners/blue.webp'

function MembershipContent () {
    return <>
        <Container className={'Page'} style={{height: '100vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Blue} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <hr/>
            <div style={{height: '3rem'}}/>
            <h1>Membership registration is now closed!</h1>
            <p>Check back in next fall when general membership reopens!</p>
            <div style={{height: '3rem'}}/>
            <hr/>
        </Container>
    </>
}

function Membership() {
    return <>
        <Topbar/>
        <MembershipContent/>
        <BottomBar/>
    </>
}

export default Membership;


