import React from 'react';
import Topbar from "../components/topbar";
import BottomBar from "../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/page.css';

import Purple from '../img/banners/purple.png';
import Smiles from '../img/about/smiles.webp'

function AboutContent () {
    return <>
        <Container className={'Page'} style={{height: '120vh'}}>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Welcome to the vibrant community of the Indian Students Association at
                Berkeley!</h1>
            <div style={{height: '3rem'}}/>
            <img src={Smiles} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <p>
                ISA strives to create an environment where students can connect, celebrate, and engage in a variety of cultural, social, and educational activities. Our flagship events Garba, Diwali, and Holi are festive celebrations that offer students the opportunity to make friends, deepen their cultural roots, and provide a home away from home. Join us to help foster a sense of unity and pride in our shared heritage at UC Berkeley.
            </p>
        </Container>
    </>
}

function About() {
    return <>
    <Topbar/>
        <AboutContent/>
        <BottomBar/>
    </>
}

export default About;

