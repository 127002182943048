import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/diwali/2022banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '140vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Diwali 2022</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Friday, November 18, 2022 at 5:30 PM - 9 PM PST,
                Pauley Hall
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                Just a reminder that tickets are non refundable and non transferable. Please bring your student ID to the event. GET HYPED FOR DIWALI!!

                If you have any questions, feel free to reach out to contact@isa.berkeley.edu


            </p>
        </Container>
    </>
}

export default function Diwali2022() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}


