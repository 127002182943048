import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/holi/2024banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '140vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Holi 2024</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Date/Time: Saturday March 16th from 11am - 3pm,
                Location: Foothill Parking Lot
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                For all ISA Holi ticket-related matters, please Contact us at finance.isaberkeley@gmail.com. We may be unable to resolve any ticket issues via our Instagram, Facebook, and other DM’s at this time. For any non-ticket-related matters, suggestions, or notes about or events, feel free to contact us at isaberkeley@gmail.com.
            </p>
            <p>
                Please include the name of the ticket holder, SID, and items purchased in the description of your Venmo transaction and double-check your payments (especially when paying for multiple items)


            </p>
            <p>
                To satisfy campus requirements to be eco-friendly, we ask that you not bring in outside colors. ISA Color Packets will be released for purchase this Sunday, 3/10 and will be available to purchase until Tuesday, 3/12


            </p>
            <p>
                Please note that purchasing only color packet(s) is not an admission to ISA Holi, you must purchase a ticket to enter the event


            </p>
            <p>
                Cash Payments will be accepted on Mon 3/11, Tue 3/12, and Wed 3/13 (location and time TBD). If you have purchased a color packet, you may also collect it at this time. Attendees are welcome to pay via friends’ Venmo / Zelle with appropriate messaging.


            </p>
            <p>
                if you are paying online, all payments for your ticket must be made by Monday, 3/11. If you are paying cash, all payment for your ticket must be made by Wednesday, 3/13. If you have not paid in full by the respective date, the ticket will be cancelled the next day to avoid any last-minute cancellations or confusion regarding ticket validity.


            </p>
        </Container>
    </>
}

export default function Holi2024() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}

