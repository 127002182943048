import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/onam/2023banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '140vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Holi 2023</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Saturday, August 26, 2023 at 11 AM - 2 PM PST,
                Krutch Theater at the UC Berkeley Clark Kerr Campus
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                The Indian Students Association (ISA) and South Indian Society (SIS) joyfully invite you to the first-ever on-campus Onam celebration! Immerse yourself in the vibrant colors, rich traditions, and heartwarming spirit of Onam, one of the most cherished festivals in the South Indian calendar.

                This event will be held on Saturday, August 26, 2023 at 11 AM - 2 PM PST in the Krutch Theater at the UC Berkeley Clark Kerr Campus.

                Tickets are non-refundable and non-transferable. Food is included in your ticket! Please note that food is not allergen friendly and includes ingredients such as peanuts, coconut, and dairy.

                We encourage white semi-formal attire.
            </p>
            <p>
                **Our photography team will be taking pictures throughout the event! By purchasing a ticket, you are giving permission to be photographed and have your images be posted online.**
            </p>
            <p>
                At the event, you will have a chance to savor the exquisite flavors of the traditional Onam Sadhya, through a light brunch featuring a delectable spread of vegetarian delicacies from Kerala. Witness the creativity of attendees as they design intricate rangolis, known as Pookkalam, to adorn the venue. Enjoy a captivating performance from Berkeley's premeire Carnatic musical group, Laya of Berkeley! Finally, embrace the festive spirit by wearing your best ethnic attire and get the chance to meet other South-Asian students!

                This event promises to be an unforgettable experience, bringing together students from various backgrounds to celebrate unity, diversity, and the essence of Onam. Whether you are familiar with this festival or not, all are welcome to join in the festivities and experience the warmth of South Indian culture.

                For more information about tickets and updates about the event, please visit our Instagram pages, @isaberkeley and @ucbsouthindiansociety.

                isa.berkeley.edu x southindiansociety.berkeley.edu
            </p>
        </Container>
    </>
}

export default function Onam2023() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}

