import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/holi/2023banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '120vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Holi 2023</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Saturday, March 18, 2023 at 11 AM - 2:30 PM PST,
                Foothill Parking lot
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                Splash into the kaleidoscopic revelry of ISA Holi with bursts of color and infectious energy! The air is filled with the vibrancy of this joyous occasion, as friends and strangers alike come together to celebrate the triumph of good over evil. Join us for an afternoon filled with lively music, playful water fights, and a riot of colors that paint the sky. As we toss vibrant powders and embrace the spirit of unity, students create unforgettable memories and celebrate the arrival of spring with boundless enthusiasm.
            </p>
        </Container>
    </>
}

export default function Holi2023() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}


