import React from 'react';
import Topbar from "../components/topbar";
import BottomBar from "../components/bottombar";
import { Button, Container, Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/page.css';
import '../css/past.css'

import garba2022 from '../img/garba/2022banner.png';
import diwali2022 from '../img/diwali/2022banner.png';
import holi2023 from '../img/holi/2023banner.png';
import onam2023 from '../img/onam/2023banner.png';
import garba2023 from '../img/garba/2023banner.png';
import diwali2023 from '../img/diwali/2023banner.png';
import holi2024 from '../img/holi/2024banner.png';
import artnight2024 from '../img/artnight/2024banner.png';

function PastContent () {

    const events = [
        {
            name: "Garba 2022",
            banner: garba2022,
            link: '/garba2022'
        }, {
            name: "Diwali 2022",
            banner: diwali2022,
            link:'/diwali2022'
        }, {
            name: "Holi 2023",
            banner: holi2023,
            link: '/holi2023'
        }, {
            name: "Onam 2023",
            banner: onam2023,
            link: '/onam2023'
        }, {
            name: "Garba 2023",
            banner: garba2023,
            link: '/garba2023'
        }, {
            name: "Diwali 2023",
            banner: diwali2023,
            link: '/diwali2023'
        }, {
            name: 'Holi 2024',
            banner: holi2024,
            link: '/holi2024'
        }, {
            name: 'Art Night 2023',
            banner: artnight2024,
            link:'/artnight2024'
        }
    ]

    // @ts-ignore
    return <>
        <Container className={'Page'} >
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}> Browse Through Snapshots of our Past Events!</h1>
            <div style={{height: '3rem'}}/>
            <div className={'d-flex flex-row flex-wrap'}>
                {events.reverse().map((name) => <>
                    <Card className={'EventCard'}>
                        <Card.Img variant={'top'} style={{height:'70%'}} src={name.banner}></Card.Img>
                        <Card.Body className={'d-flex flex-row justify-content-between'}>
                            <Card.Title>{name.name}</Card.Title>
                            <div>
                                <Button variant={'danger'} href={name.link}>Learn More</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </>)}
            </div>
            <div style={{height:'10rem'}}/>
        </Container>
    </>
}

function Past() {
    return <>
        <Topbar/>
        <PastContent/>
        <BottomBar/>
    </>
}

export default Past;

