import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/onam/2024banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '140vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Onam 2024</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Saturday, September 7, 2024 at 11 AM - 2 PM PST,
                Krutch Theater at the UC Berkeley Clark Kerr Campus
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                If you have any questions, feel free to reach out to contact@isa.berkeley.edu. Purchase Tickets below:
            </p>
            <Button variant={'danger'} href={'https://forms.gle/JYagNyvLGkA4Z6uZ7'}>Tickets</Button>
        </Container>
    </>
}

export default function Onam2024() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}

