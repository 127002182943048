import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import Home from "./pages/home";
import About from "./pages/about";
import Past from "./pages/pastevents";
import Membership from "./pages/membership";
import Join from "./pages/join";
import Work from "./pages/work";

import Garba2022 from "./pages/garba/garba2022";
import Diwali2022 from "./pages/diwali/diwali2022";
import Holi2023 from "./pages/holi/holi2023";
import Onam2023 from "./pages/onam/onam2023";
import Garba2023 from "./pages/garba/garba2023";
import Diwali2023 from "./pages/diwali/diwali2023";
import Holi2024 from "./pages/holi/holi2024";
import Artnight2024 from "./pages/artnight/artnight2024";
import Onam2024 from "./pages/onam/onam2024";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>
    }, {
        path: "/home",
        element: <Home/>
    }, {
        path: "/about",
        element: <About/>
    }, {
        path: "/pastevents",
        element: <Past/>
    }, {
        path: "/membership",
        element: <Membership/>
    }, {
        path: "/join",
        element: <Join/>
    }, {
        path: "/work",
        element: <Work/>
    }, {
        path:'garba2022',
        element: <Garba2022/>
    }, {
        path: 'diwali2022',
        element: <Diwali2022/>
    }, {
        path: 'holi2023',
        element: <Holi2023/>
    }, {
        path: 'onam2023',
        element: <Onam2023/>
    }, {
        path: 'garba2023',
        element: <Garba2023/>
    }, {
        path: 'diwali2023',
        element: <Diwali2023/>
    }, {
        path: 'holi2024',
        element: <Holi2024/>
    }, {
        path:'artnight2024',
        element: <Artnight2024/>
    }, {
        path: 'onam2024',
        element: <Onam2024/>
    }
]);

root.render(
    <React.StrictMode>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
            integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
            crossOrigin="anonymous"
        />
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Handjet:wght@100..900&display=swap');
        </style>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Handjet:wght@200&family=Honk&display=swap');
        </style>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway"/>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
