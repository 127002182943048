import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/diwali/2023banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '120vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Diwali 2023</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Wednesday, November 8, 2023 at 6 PM - 9 PM PST,
                Pauley Hall
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                Step into the dazzling world of our annual Diwali celebration. ISA Diwali is an iconic event, which highlights South Asian performance groups across campus such as UCB Azaad, Cal Bhangra, Laya of Berkeley, and more. The energy from these performances, the rich aromas of traditional Indian food, and the joyous laughter of friends converge in this magical event. Our celebration is an open invitation for Berkeley students to experience the warmth and cultural significance of this Festival of Lights.
            </p>
        </Container>
    </>
}

export default function Diwali2023() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}