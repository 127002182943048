import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/topbar.css'
import Logo from '../img/BlackLogo.png';


function Topbar() {
    return (
       <Navbar bg="light" data-bs-theme="light" className={'Topbar'}>
           <Container>
               <Navbar.Brand href="home">
                   <img
                       src={Logo}
                       width="70"
                       height="70"
                       className="d-inline-block align-top"
                       alt="React Bootstrap logo"
                   />
               </Navbar.Brand>
               <Nav className="me-auto">
                   <Nav.Link href="about">About</Nav.Link>
                   <Nav.Link href="pastevents">Past Events</Nav.Link>
                   <Nav.Link href="membership">Membership</Nav.Link>
                   <Nav.Link href="join">Join</Nav.Link>
                   <Nav.Link href="work">Work With Us</Nav.Link>
               </Nav>
           </Container>
       </Navbar>
    );
}

export default Topbar;




