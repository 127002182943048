import React from 'react';
import Topbar from "../components/topbar";
import BottomBar from "../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/page.css';

import Purple from '../img/banners/purple.png'

function WorkContent () {
    return <>
        <Container className={'Page'} style={{height: '100vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Purple} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <hr/>
            <div style={{height: '3rem'}}/>
            <h1>We can't wait to work with you!!</h1>
            <p>Please check out the contact form here:</p>
            <Button variant={'danger'}
                    href={'https://docs.google.com/forms/d/e/1FAIpQLSd_pF_zJe1PLo4EuK2e7H_sNTSeqGzwt53ONHYBfkKQCaVn2w/viewform'}>Contact
                us</Button>
            <div style={{height: '3rem'}}/>

            <p>Email: contact@isa.berkeley.edu</p>
            <p>Instagram: @isaberkeley</p>
            <div style={{height: '3rem'}}/>
            <hr/>
        </Container>
    </>
}

function Work() {
    return <>
    <Topbar/>
        <WorkContent/>
        <BottomBar/>
    </>
}

export default Work;


