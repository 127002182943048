import React from 'react';
import Topbar from "../components/topbar";
import BottomBar from "../components/bottombar";
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/home.css';

import GarbaBanner from '../img/garba/2023banner.png';
import Drums from '../img/banners/drums.png';
import Purple from '../img/banners/purple.png';


function HomeSection1() {

    const downRem = 22;

    return (<>
        <div className={'Home-back'}>
            <a style={{textDecoration:'none'}} href={'/onam2024'}>
                <div className={'Home-front d-flex flex-row align-items-center'}>
                    <div>
                        <div style={{height: `${downRem}rem`}}/>
                        <p>UPCOMING</p>
                        <h1 style={{fontWeight: '900'}}>SIS x ISA Onam 2024</h1>
                        <p>Tickets now available for purchase. Click to learn more!</p>
                        <div style={{height: `${downRem / 2}rem`}}/>
                        <div className={'d-flex flex-column align-items-center'} style={{width: '96vw'}}>
                            <p style={{marginBottom: '0'}}>scroll down to read more about ISA</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor"
                                 className="bi bi-chevron-compact-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </>);
}

function HomeSection2() {
    return(<>
        <div className={'HomeN d-flex flex-column align-items-center'} style={{height:'85vh'}}>
            <img src={GarbaBanner} width={'60%'}/>
            <div style={{height:'3rem'}}/>
            <div style={{width:'100%'}}>
                <p>PAST EVENT</p>
                <h1 style={{fontWeight: '900'}}>Garba 2023</h1>
                <p>We previously held our annual Garba event! We dove into the rhythmic celebration of our annual Garba event! ISA x Indus Garba is a historic collaboration, featuring a performance from UC Berkeley’s very own Raas Ramzat.</p>
                <p>Learn more about our past events below:</p>
                <Button variant={'danger'} href={'/pastevents'}>Past Events</Button>
            </div>
        </div>
    </>)
}

function HomeSection3() {
    return(<>
        <div className={'HomeN d-flex flex-column align-items-center'} style={{height:'55vh'}}>
            <img src={Drums} width={'60%'}/>
            <div style={{height:'3rem'}}/>
            <div style={{width:'100%'}}>
                <h1 style={{fontWeight: '900'}}>General Membership Program</h1>
                <p>Get access to exclusive social events and activities, discounts and ticket presales for major events, access to a virtual member forumn amd a fun, tight-knit campus community passionate about South-Asian culture</p>
                <Button variant={'danger'} href={'membership'}>Membership</Button>
            </div>
        </div>
    </>)
}

function HomeSection4() {
    return (<>
        <div className={'HomeN d-flex flex-column align-items-center'} style={{height: '55vh'}}>
            <img src={Purple} width={'60%'}/>
            <div style={{height: '3rem'}}/>
            <div style={{width: '100%'}}>
                <h1 style={{fontWeight: '900'}}>Newsletter</h1>
                <p>Never miss another ticket drop again.</p>
                <Button variant={'danger'} href={'https://docs.google.com/forms/d/e/1FAIpQLSfR1-Ka8G3OA3Nub_ZydJy4CkeabnACb4a4nPIg-d8LfCeJjg/viewform?usp=sf_link'}>Get Updates</Button>
            </div>
        </div>
    </>)
}

function Home() {
    return (<>
        <Topbar/>
        <HomeSection1/>
        <HomeSection2/>
        <HomeSection3/>
        <HomeSection4/>
        <BottomBar/>
    </>);
}

export default Home;
