import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/artnight/2024banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '140vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Art Night 2024</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Tuesday, April 23, 6-8:30 PM,
                Campanile Esplanade at UC Berkeley
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                Our art night will also include a student fashion show, highlighting the beauty of South Asian clothing & culture!

                If you are interested in modeling for the South Asian Fashion show portion of the event, please fill out this form: https://tinyurl.com/ISAFashion24.

                Please feel free to reach out to contact.isa@berkeley.edu with any questions!
            </p>
        </Container>
    </>
}

export default function Artnight2024() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}


