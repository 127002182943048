import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/bottombar.css'

function BottomBar() {
    return (<>
        <div className={'BottomBar d-flex flex-column justify-content-end'}>
            <div className={'BottomText d-flex flex-row'} style={{margin:'3rem 3rem 0rem 3rem'}}>
                <div className={'d-flex flex-column'}>
                    <a className={'BottomLink'} href={'mailto:contact@isa.berkeley.edu'}>email</a>
                    <a className={'BottomLink'} href={'https://www.instagram.com/isaberkeley/'}>instagram</a>
                </div>
                <div style={{width:'3rem'}}/>
                <div className={'d-flex flex-column'}>
                    <a className={'BottomLink'} href={'https://www.facebook.com/isaberkeley'}>facebook</a>
                    <a className={'BottomLink'} href={'https://www.linkedin.com/company/indian-students-association-berkeley/'}>linkedin</a>
                </div>
                <div style={{width:'3rem'}}/>
                <div>
                    <a href="https://www.ocf.berkeley.edu">
                        <img src="http://www.ocf.berkeley.edu/hosting-logos/ocf-hosted-penguin.svg"
                             alt="Hosted by the OCF" style={{border: "0"}}/>
                    </a>
                </div>
            </div>
            <div className="marquee">
                <span>
                    ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                    ~ Hey whats up
                    ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                    ~ I'm honestly proud if you if you're seeing this
                    ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                    ~ Free Me
                    ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                    ~ Lord what is wrong with you
                    ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                     ~ INDIAN STUDENTS ASSOCIATION AT THE UNIVERSITY OF CALIFORNIA, BERKELEY
                    ~ Okay byeeeeeeee
                </span>
            </div>
        </div>
    </>);
}

export default BottomBar;


