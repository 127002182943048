import React from 'react';
import Topbar from "../components/topbar";
import BottomBar from "../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';
import '../css/page.css';

import Blue from '../img/banners/blue_2.png'

function JoinContent () {
    return <>
        <Container className={'Page'} style={{height: '100vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Blue} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <hr/>
            <div style={{height: '3rem'}}/>
            <h1>Applications are Open!</h1>
            <p>Applications are due by September 20th, 11:59pm for the 2024-2025 year.</p>
            <Button variant={'danger'} href={'https://docs.google.com/forms/d/e/1FAIpQLSdjg_7bDpFKj2l-EM2VNNXypnXc6eyoMaRacFByu9K6v2izzg/viewform'}>Application</Button>
            <div style={{height: '3rem'}}/>
            <hr/>
        </Container>
    </>
}

function Join() {
    return <>
        <Topbar/>
        <JoinContent/>
        <BottomBar/>
    </>
}

export default Join;


