import React from 'react';
import Topbar from "../../components/topbar";
import BottomBar from "../../components/bottombar";
import { Button, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/App.css';
import '../../css/page.css';

import Banner from '../../img/garba/2022banner.png';

function Content () {
    return <>
        <Container className={'Page'} style={{height: '120vh'}}>
            <div style={{height: '3rem'}}/>
            <img src={Banner} width={'100%'}/>
            <div style={{height: '3rem'}}/>
            <h1 style={{fontSize: '3rem'}}>Garba 2022</h1>
            <div style={{height: '2rem'}}/>
            <p style={{fontWeight:'800'}}>
                Thursday, October 27, 2022 at 6 PM - 9 PM PST,
                Pauley Hall
            </p>
            <div style={{height: '2rem'}}/>
            <p>
                Dive into the rhythmic celebration of our annual Garba event! ISA x Indus Garba is a historic
                collaboration, featuring a performance from UC Berkeley’s very own Raas Ramzat. The beats of iconic
                South Asian music, the vibrant colors of traditional attire, and the infectious energy of the dance
                floor all come together to create a night of joy and cultural richness. Whether you're a seasoned Garba
                enthusiast or a first-timer, our event is open to any Cal student eager to immerse themselves in the
                spirit of this lively folk dance.
            </p>
        </Container>
    </>
}

function Garba2022() {
    return <>
        <Topbar/>
        <Content/>
        <BottomBar/>
    </>
}

export default Garba2022;

